/******************************************/
/* Base
/******************************************/

$base-background-color: #f7f7f7;
$main-font: 'Open Sans',sans-serif;
$main-color: #494949;
$accent-font: 'Open Sans',sans-serif;

/******************************************/
/* Form
/******************************************/
$form-color: #2348ce;
$form-label-clr: #494949;
$form-label-weight: 700;
$form-element-color: #8b8b8b;

$dark-primary-text: #494949;
$light-primary-text: white;

/******************************************/
/* Header
/******************************************/

$header-bg: #FFFFFF;
$header-clr: #2348ce;

/******************************************/
/* Button
/******************************************/

$main-btn-bg: #2348ce;
$main-btn-color: #FFF;
$main-btn-radius: 0;
$main-btn-weight: bold;
$main-btn-txt-transform: uppercase;

/******************************************/
/* Slider
/******************************************/

$slider-main: #4E138C;
$slider-accent: #FF6202;
$slider-arrows: #4E138C;

/******************************************/
/* FAQ
/******************************************/

$questions-arrow: #4E138C;

/******************************************/
/* Footer
/******************************************/

$footer-bg: #FFF;
$footer-color: #494949;
$footer-questions-color: #494949;
$footer-shadow: 0 1px 12px rgba(#000, .08);

/******************************************/
/* Steps
/******************************************/

$steps-bg: #ffde00;
$steps-main-color: #2348ce;
$steps-active-color: #2348ce;

/******************************************/
/* Secure
/******************************************/

$secure-border-bgd: #ffde00;
$secure-rds: 9px;
$secure-clr: #494949;
$secure-font: $main-font;
$secure-weight: normal;

$secure-subtitle-font: $main-font;
$secure-subtitle-weight: 300;

/******************************************/
/* Support
/******************************************/

$support-icons-color: #2348ce;

/******************************************/
/* Traveler
/******************************************/

$traveler-bg: #FAFAFA;
$traveler-clr: #333333;

/******************************************/
/* Product
/******************************************/

$product-bg: #0D1C52;
$product-clr: #FAFAFA;
$product-weight: lighter;

/******************************************/
/* Agreement
/******************************************/

$agreement-clr: #535050;
$agreement-bg: #FAFAFA;

/******************************************/
/* Summary
/******************************************/

$summary-border-radius: 0;
$summary-bg: #FAFAFA;
$summary-clr: #494949;
$summary-separator-width: 1px;
$summary-separator-color: #D8D8D8;
$summary-pill-bgd: #494949;
$summary-box-shadow:  0 2px 50px rgba(#000, .15);
$summary-title-color: #494949;
$summary-box-shadow:  0 2px 50px rgba(#000000, .15);
